import { render, staticRenderFns } from "./AppModal.vue?vue&type=template&id=37ce3d1a&"
import script from "./AppModal.vue?vue&type=script&lang=js&"
export * from "./AppModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppDynamicComponent: require('/furanet/sites/runnea.pt/web/htdocs/nuxt/components/AppDynamicComponent.vue').default})
