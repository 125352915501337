import axios from 'axios'

const crypto = require('crypto')

const md5 = data => crypto.createHash('md5').update(data).digest('hex')

class AxiApi {
  constructor (api) {
    this.GlobalData = {}
    this.GlobalData.zones = {}
    this.api = api
  }

  insert (config, data, zone, index) {
    if (typeof (this.GlobalData.zones[zone]) === 'undefined') {
      this.GlobalData.zones[zone] = []
      this.GlobalData.zones[zone][index] = []
      this.GlobalData.zones[zone][index] = config
      this.GlobalData.zones[zone][index].content.info = []
      if (typeof (data.contents) !== 'undefined') {
        for (let art = 0; art < data.contents.length; art++) {
          this.GlobalData.zones[zone][index].content.info.push(data.contents[art])
        }
      } else {
        this.GlobalData.zones[zone][index].content.info = data
      }
    } else if (typeof (this.GlobalData.zones[zone][index]) === 'undefined') {
      this.GlobalData.zones[zone][index] = []
      this.GlobalData.zones[zone][index] = config
      this.GlobalData.zones[zone][index].content.info = []
      if (typeof (data.contents) !== 'undefined') {
        for (let art = 0; art < data.contents.length; art++) {
          this.GlobalData.zones[zone][index].content.info.push(data.contents[art])
        }
      } else {
        this.GlobalData.zones[zone][index].content.info = data
      }
    } else if (typeof (data.contents) !== 'undefined') {
      for (let art = 0; art < data.contents.length; art++) {
        this.GlobalData.zones[zone][index].content.info.push(data.contents[art])
      }
    } else {
      this.GlobalData.zones[zone][index].content.info = data
    }
  }

  get () {
    return { GlobalData: this.GlobalData }
  }

  flatArray (items, itemObj) {
    if (Array.isArray(itemObj)) {
      itemObj.forEach((item) => {
        items = this.flatArray(items, item)
      })
    } else {
      items.push(itemObj)
    }

    return items
  }

  async createComponentData (ConfigFile, store, country) {
    const promises = []
    const data = []
    if (ConfigFile) {
      for (const zone in ConfigFile.zones) {
        const ConfigFileZone = this.flatArray([], ConfigFile.zones[zone])
        for (let index = 0; index < ConfigFileZone.length; index++) {
          if (typeof (ConfigFileZone[index].ConfigurationFile) !== 'undefined') {
            // primero lo que venga en info
            if (typeof (ConfigFileZone[index].ConfigurationFile.content.info) !== 'undefined') {
              // de aquí eliminamos los vacios
              if (Array.isArray(ConfigFileZone[index].ConfigurationFile.content.info)) {
                ConfigFileZone[index].ConfigurationFile.content.info = ConfigFileZone[index].ConfigurationFile.content.info.filter(e => Object.keys(e).length > 0)
              }
              this.insert(ConfigFileZone[index].ConfigurationFile, ConfigFileZone[index].ConfigurationFile.content.info, zone, index)
            }
            if (typeof (ConfigFileZone[index].ConfigurationFile.content.json) !== 'undefined') {
              await axios.get(
                ConfigFileZone[index].ConfigurationFile.content.json
              ).then((json) => {
                this.insert(ConfigFileZone[index].ConfigurationFile, json.data, zone, index)
              })
            } else if (typeof (ConfigFileZone[index].ConfigurationFile.content.jsons) !== 'undefined') {
              const jsonContents = []
              await ConfigFileZone[index].ConfigurationFile.content.jsons.forEach(async (jsonFile) => {
                await axios.get(
                  jsonFile
                ).then((json) => {
                  jsonContents.push(json.data)
                })
              })
              this.insert(ConfigFileZone[index].ConfigurationFile, jsonContents, zone, index)
            } else if (typeof (ConfigFileZone[index].ConfigurationFile.content.api) !== 'undefined') {
              const projectId = ((ConfigFileZone[index].ConfigurationFile.content.api.name === 'prices') && (ConfigFileZone[index].ConfigurationFile.content.api.type === 'list') && country && country.id) || '24'
              let query = this.api + '/app/v1/' + ConfigFileZone[index].ConfigurationFile.content.api.name + '/' + ConfigFileZone[index].ConfigurationFile.content.api.type + '?'
              let i, value
              query += '_p=' + projectId + '&'
              // eslint-disable-next-line no-constant-condition
              if ('true' === 'false') {
                query += '_c=0&'
              }
              // Parametros
              if (typeof (ConfigFileZone[index].ConfigurationFile.content.api.params) !== 'undefined') {
                for ([i, value] of Object.entries(ConfigFileZone[index].ConfigurationFile.content.api.params)) {
                  query += i + '=' + value + '&'
                }
              }
              // Filtros
              query += 'filters='
              for ([i, value] of Object.entries(ConfigFileZone[index].ConfigurationFile.content.api.filters)) {
                query += '/' + i + '-' + value.values.join()
              }
              // Productos
              if (typeof (ConfigFileZone[index].ConfigurationFile.content.api.products) !== 'undefined' && typeof (ConfigFileZone[index].ConfigurationFile.content.api.products_type) !== 'undefined') {
                query += '&products=' + ConfigFileZone[index].ConfigurationFile.content.api.products + '&products_type=' + ConfigFileZone[index].ConfigurationFile.content.api.products_type
              }
              // Orden y direccion
              if (typeof (ConfigFileZone[index].ConfigurationFile.content.api.orders) !== 'undefined') {
                if (typeof ConfigFileZone[index].ConfigurationFile.content.api.orders.order !== 'undefined') {
                  query += '&order=' + ConfigFileZone[index].ConfigurationFile.content.api.orders.order + '&' + 'direction=' + (ConfigFileZone[index].ConfigurationFile.content.api.orders.direction ? ConfigFileZone[index].ConfigurationFile.content.api.orders.direction : '')
                }
              }
              // Contenidos
              query += '&contents=' + (ConfigFileZone[index].ConfigurationFile.content.api.contents ? ConfigFileZone[index].ConfigurationFile.content.api.contents : 4)

              // Proyecto Alternativo
              if (ConfigFileZone[index].ConfigurationFile.content.api.proyect) {
                query += '&proyect=' + ConfigFileZone[index].ConfigurationFile.content.api.proyect
              }
              const code = md5(query)
              if (!store.state.modules[code]) {
                promises.push(
                  await store.dispatch(
                    'loadModules',
                    query
                  )
                )
                data.push({ configurationFile: ConfigFileZone[index].ConfigurationFile, module: {}, zone, index, code })
              } else {
                this.insert(ConfigFileZone[index].ConfigurationFile, store.state.modules[code], zone, index)
              }
            }
          }
        }
      }

      await Promise.all(promises)
      const deleteGlobalData = {}
      for (const property in data) {
        if (store.state.modules[data[property].code]) {
          this.insert(data[property].configurationFile, store.state.modules[data[property].code], data[property].zone, data[property].index)
        } else {
          if (!deleteGlobalData[data[property].zone]) {
            deleteGlobalData[data[property].zone] = []
          }
          deleteGlobalData[data[property].zone].push(data[property].index)
        }
      }
      Object.keys(deleteGlobalData).forEach((z) => {
        if (this.GlobalData.zones[z]) {
          this.GlobalData.zones[z] = this.GlobalData.zones[z].filter((o, i) => !deleteGlobalData[z].includes(i))
        }
      })
    }
  }
}
export default AxiApi
