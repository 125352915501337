export const AdvertisingProductsCard = () => import('../../components/AdvertisingProductsCard.vue' /* webpackChunkName: "components/advertising-products-card" */).then(c => wrapFunctional(c.default || c))
export const AppBreadcrumb = () => import('../../components/AppBreadcrumb.vue' /* webpackChunkName: "components/app-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const AppCardsCarousel = () => import('../../components/AppCardsCarousel.vue' /* webpackChunkName: "components/app-cards-carousel" */).then(c => wrapFunctional(c.default || c))
export const AppDynamicComponent = () => import('../../components/AppDynamicComponent.vue' /* webpackChunkName: "components/app-dynamic-component" */).then(c => wrapFunctional(c.default || c))
export const AppDynamicItem = () => import('../../components/AppDynamicItem.vue' /* webpackChunkName: "components/app-dynamic-item" */).then(c => wrapFunctional(c.default || c))
export const AppFinder = () => import('../../components/AppFinder.vue' /* webpackChunkName: "components/app-finder" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppLangSwitcher = () => import('../../components/AppLangSwitcher.vue' /* webpackChunkName: "components/app-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const AppLink = () => import('../../components/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c))
export const AppLogo = () => import('../../components/AppLogo.vue' /* webpackChunkName: "components/app-logo" */).then(c => wrapFunctional(c.default || c))
export const AppModal = () => import('../../components/AppModal.vue' /* webpackChunkName: "components/app-modal" */).then(c => wrapFunctional(c.default || c))
export const AppSlider = () => import('../../components/AppSlider.vue' /* webpackChunkName: "components/app-slider" */).then(c => wrapFunctional(c.default || c))
export const AppSocial = () => import('../../components/AppSocial.vue' /* webpackChunkName: "components/app-social" */).then(c => wrapFunctional(c.default || c))
export const AppTags = () => import('../../components/AppTags.vue' /* webpackChunkName: "components/app-tags" */).then(c => wrapFunctional(c.default || c))
export const AppWysiwyg = () => import('../../components/AppWysiwyg.vue' /* webpackChunkName: "components/app-wysiwyg" */).then(c => wrapFunctional(c.default || c))
export const ArticleCard = () => import('../../components/ArticleCard.vue' /* webpackChunkName: "components/article-card" */).then(c => wrapFunctional(c.default || c))
export const ArticleCardMedium = () => import('../../components/ArticleCardMedium.vue' /* webpackChunkName: "components/article-card-medium" */).then(c => wrapFunctional(c.default || c))
export const ArticleCardNumbers = () => import('../../components/ArticleCardNumbers.vue' /* webpackChunkName: "components/article-card-numbers" */).then(c => wrapFunctional(c.default || c))
export const ArticleCardPhoto = () => import('../../components/ArticleCardPhoto.vue' /* webpackChunkName: "components/article-card-photo" */).then(c => wrapFunctional(c.default || c))
export const ArticleCardSlim = () => import('../../components/ArticleCardSlim.vue' /* webpackChunkName: "components/article-card-slim" */).then(c => wrapFunctional(c.default || c))
export const ArticleFinder = () => import('../../components/ArticleFinder.vue' /* webpackChunkName: "components/article-finder" */).then(c => wrapFunctional(c.default || c))
export const AuthLoginForm = () => import('../../components/AuthLoginForm.vue' /* webpackChunkName: "components/auth-login-form" */).then(c => wrapFunctional(c.default || c))
export const AuthLoginModal = () => import('../../components/AuthLoginModal.vue' /* webpackChunkName: "components/auth-login-modal" */).then(c => wrapFunctional(c.default || c))
export const AuthorLeaf = () => import('../../components/AuthorLeaf.vue' /* webpackChunkName: "components/author-leaf" */).then(c => wrapFunctional(c.default || c))
export const AuthorMiniLeaf = () => import('../../components/AuthorMiniLeaf.vue' /* webpackChunkName: "components/author-mini-leaf" */).then(c => wrapFunctional(c.default || c))
export const BrandCard = () => import('../../components/BrandCard.vue' /* webpackChunkName: "components/brand-card" */).then(c => wrapFunctional(c.default || c))
export const BrandCardLite = () => import('../../components/BrandCardLite.vue' /* webpackChunkName: "components/brand-card-lite" */).then(c => wrapFunctional(c.default || c))
export const BrandFinder = () => import('../../components/BrandFinder.vue' /* webpackChunkName: "components/brand-finder" */).then(c => wrapFunctional(c.default || c))
export const BrandsCarousel = () => import('../../components/BrandsCarousel.vue' /* webpackChunkName: "components/brands-carousel" */).then(c => wrapFunctional(c.default || c))
export const BrandsProduct = () => import('../../components/BrandsProduct.vue' /* webpackChunkName: "components/brands-product" */).then(c => wrapFunctional(c.default || c))
export const ChatCard = () => import('../../components/ChatCard.vue' /* webpackChunkName: "components/chat-card" */).then(c => wrapFunctional(c.default || c))
export const CommentCard = () => import('../../components/CommentCard.vue' /* webpackChunkName: "components/comment-card" */).then(c => wrapFunctional(c.default || c))
export const ComparisonCard = () => import('../../components/ComparisonCard.vue' /* webpackChunkName: "components/comparison-card" */).then(c => wrapFunctional(c.default || c))
export const CountryChange = () => import('../../components/CountryChange.vue' /* webpackChunkName: "components/country-change" */).then(c => wrapFunctional(c.default || c))
export const DiscountCodeCard = () => import('../../components/DiscountCodeCard.vue' /* webpackChunkName: "components/discount-code-card" */).then(c => wrapFunctional(c.default || c))
export const Error404 = () => import('../../components/Error404.vue' /* webpackChunkName: "components/error404" */).then(c => wrapFunctional(c.default || c))
export const GeoLocalizatiosUser = () => import('../../components/GeoLocalizatiosUser.vue' /* webpackChunkName: "components/geo-localizatios-user" */).then(c => wrapFunctional(c.default || c))
export const IconCard = () => import('../../components/IconCard.vue' /* webpackChunkName: "components/icon-card" */).then(c => wrapFunctional(c.default || c))
export const InfluencerCard = () => import('../../components/InfluencerCard.vue' /* webpackChunkName: "components/influencer-card" */).then(c => wrapFunctional(c.default || c))
export const InquiryCard = () => import('../../components/InquiryCard.vue' /* webpackChunkName: "components/inquiry-card" */).then(c => wrapFunctional(c.default || c))
export const InquiryFinder = () => import('../../components/InquiryFinder.vue' /* webpackChunkName: "components/inquiry-finder" */).then(c => wrapFunctional(c.default || c))
export const InquiryInquiryCard = () => import('../../components/InquiryInquiryCard.vue' /* webpackChunkName: "components/inquiry-inquiry-card" */).then(c => wrapFunctional(c.default || c))
export const InquiryModal = () => import('../../components/InquiryModal.vue' /* webpackChunkName: "components/inquiry-modal" */).then(c => wrapFunctional(c.default || c))
export const InquiryProductCard = () => import('../../components/InquiryProductCard.vue' /* webpackChunkName: "components/inquiry-product-card" */).then(c => wrapFunctional(c.default || c))
export const InquiryResponseCard = () => import('../../components/InquiryResponseCard.vue' /* webpackChunkName: "components/inquiry-response-card" */).then(c => wrapFunctional(c.default || c))
export const InquiryTitleCard = () => import('../../components/InquiryTitleCard.vue' /* webpackChunkName: "components/inquiry-title-card" */).then(c => wrapFunctional(c.default || c))
export const ListFilters = () => import('../../components/ListFilters.vue' /* webpackChunkName: "components/list-filters" */).then(c => wrapFunctional(c.default || c))
export const ListFiltersNormalItem = () => import('../../components/ListFiltersNormalItem.vue' /* webpackChunkName: "components/list-filters-normal-item" */).then(c => wrapFunctional(c.default || c))
export const ListFiltersOfuscatedItem = () => import('../../components/ListFiltersOfuscatedItem.vue' /* webpackChunkName: "components/list-filters-ofuscated-item" */).then(c => wrapFunctional(c.default || c))
export const ListPagination = () => import('../../components/ListPagination.vue' /* webpackChunkName: "components/list-pagination" */).then(c => wrapFunctional(c.default || c))
export const MagazineArticlePhoto = () => import('../../components/MagazineArticlePhoto.vue' /* webpackChunkName: "components/magazine-article-photo" */).then(c => wrapFunctional(c.default || c))
export const MagazineArticleText = () => import('../../components/MagazineArticleText.vue' /* webpackChunkName: "components/magazine-article-text" */).then(c => wrapFunctional(c.default || c))
export const MagazineFooter = () => import('../../components/MagazineFooter.vue' /* webpackChunkName: "components/magazine-footer" */).then(c => wrapFunctional(c.default || c))
export const MagazineHeader = () => import('../../components/MagazineHeader.vue' /* webpackChunkName: "components/magazine-header" */).then(c => wrapFunctional(c.default || c))
export const MagazineModuleVideos = () => import('../../components/MagazineModuleVideos.vue' /* webpackChunkName: "components/magazine-module-videos" */).then(c => wrapFunctional(c.default || c))
export const MagazineModuleA1 = () => import('../../components/MagazineModule_A1.vue' /* webpackChunkName: "components/magazine-module-a1" */).then(c => wrapFunctional(c.default || c))
export const MagazineModuleA1B1AB3 = () => import('../../components/MagazineModule_A1_B1_AB3.vue' /* webpackChunkName: "components/magazine-module-a1-b1-a-b3" */).then(c => wrapFunctional(c.default || c))
export const MagazineModuleA1B3 = () => import('../../components/MagazineModule_A1_B3.vue' /* webpackChunkName: "components/magazine-module-a1-b3" */).then(c => wrapFunctional(c.default || c))
export const MagazineModuleA2B2C5 = () => import('../../components/MagazineModule_A2_B2_C5.vue' /* webpackChunkName: "components/magazine-module-a2-b2-c5" */).then(c => wrapFunctional(c.default || c))
export const MagazineModuleA2BC1ABC3 = () => import('../../components/MagazineModule_A2_BC1_ABC3.vue' /* webpackChunkName: "components/magazine-module-a2-b-c1-a-b-c3" */).then(c => wrapFunctional(c.default || c))
export const MagazineModuleA2BC4 = () => import('../../components/MagazineModule_A2_BC4.vue' /* webpackChunkName: "components/magazine-module-a2-b-c4" */).then(c => wrapFunctional(c.default || c))
export const MagazineModuleA4 = () => import('../../components/MagazineModule_A4.vue' /* webpackChunkName: "components/magazine-module-a4" */).then(c => wrapFunctional(c.default || c))
export const MagazineModuleA6 = () => import('../../components/MagazineModule_A6.vue' /* webpackChunkName: "components/magazine-module-a6" */).then(c => wrapFunctional(c.default || c))
export const MenuMagazine = () => import('../../components/MenuMagazine.vue' /* webpackChunkName: "components/menu-magazine" */).then(c => wrapFunctional(c.default || c))
export const MenuPrimary = () => import('../../components/MenuPrimary.vue' /* webpackChunkName: "components/menu-primary" */).then(c => wrapFunctional(c.default || c))
export const ModuleAdvertisingProducts = () => import('../../components/ModuleAdvertisingProducts.vue' /* webpackChunkName: "components/module-advertising-products" */).then(c => wrapFunctional(c.default || c))
export const ModuleAlert = () => import('../../components/ModuleAlert.vue' /* webpackChunkName: "components/module-alert" */).then(c => wrapFunctional(c.default || c))
export const ModuleArticle = () => import('../../components/ModuleArticle.vue' /* webpackChunkName: "components/module-article" */).then(c => wrapFunctional(c.default || c))
export const ModuleBanner = () => import('../../components/ModuleBanner.vue' /* webpackChunkName: "components/module-banner" */).then(c => wrapFunctional(c.default || c))
export const ModuleChats = () => import('../../components/ModuleChats.vue' /* webpackChunkName: "components/module-chats" */).then(c => wrapFunctional(c.default || c))
export const ModuleCloudTags = () => import('../../components/ModuleCloudTags.vue' /* webpackChunkName: "components/module-cloud-tags" */).then(c => wrapFunctional(c.default || c))
export const ModuleComments = () => import('../../components/ModuleComments.vue' /* webpackChunkName: "components/module-comments" */).then(c => wrapFunctional(c.default || c))
export const ModuleFeatured = () => import('../../components/ModuleFeatured.vue' /* webpackChunkName: "components/module-featured" */).then(c => wrapFunctional(c.default || c))
export const ModuleFinder = () => import('../../components/ModuleFinder.vue' /* webpackChunkName: "components/module-finder" */).then(c => wrapFunctional(c.default || c))
export const ModuleFooter = () => import('../../components/ModuleFooter.vue' /* webpackChunkName: "components/module-footer" */).then(c => wrapFunctional(c.default || c))
export const ModuleForm = () => import('../../components/ModuleForm.vue' /* webpackChunkName: "components/module-form" */).then(c => wrapFunctional(c.default || c))
export const ModuleGpt = () => import('../../components/ModuleGpt.vue' /* webpackChunkName: "components/module-gpt" */).then(c => wrapFunctional(c.default || c))
export const ModuleHeader = () => import('../../components/ModuleHeader.vue' /* webpackChunkName: "components/module-header" */).then(c => wrapFunctional(c.default || c))
export const ModuleIconList = () => import('../../components/ModuleIconList.vue' /* webpackChunkName: "components/module-icon-list" */).then(c => wrapFunctional(c.default || c))
export const ModuleImageTitle = () => import('../../components/ModuleImageTitle.vue' /* webpackChunkName: "components/module-image-title" */).then(c => wrapFunctional(c.default || c))
export const ModuleInfluencers = () => import('../../components/ModuleInfluencers.vue' /* webpackChunkName: "components/module-influencers" */).then(c => wrapFunctional(c.default || c))
export const ModuleLinkList = () => import('../../components/ModuleLinkList.vue' /* webpackChunkName: "components/module-link-list" */).then(c => wrapFunctional(c.default || c))
export const ModuleLinks = () => import('../../components/ModuleLinks.vue' /* webpackChunkName: "components/module-links" */).then(c => wrapFunctional(c.default || c))
export const ModuleMenu = () => import('../../components/ModuleMenu.vue' /* webpackChunkName: "components/module-menu" */).then(c => wrapFunctional(c.default || c))
export const ModuleNewsLetters = () => import('../../components/ModuleNewsLetters.vue' /* webpackChunkName: "components/module-news-letters" */).then(c => wrapFunctional(c.default || c))
export const ModuleOutletLinks = () => import('../../components/ModuleOutletLinks.vue' /* webpackChunkName: "components/module-outlet-links" */).then(c => wrapFunctional(c.default || c))
export const ModulePopup = () => import('../../components/ModulePopup.vue' /* webpackChunkName: "components/module-popup" */).then(c => wrapFunctional(c.default || c))
export const ModulePrices = () => import('../../components/ModulePrices.vue' /* webpackChunkName: "components/module-prices" */).then(c => wrapFunctional(c.default || c))
export const ModulePricesBargain = () => import('../../components/ModulePricesBargain.vue' /* webpackChunkName: "components/module-prices-bargain" */).then(c => wrapFunctional(c.default || c))
export const ModuleProductComparisons = () => import('../../components/ModuleProductComparisons.vue' /* webpackChunkName: "components/module-product-comparisons" */).then(c => wrapFunctional(c.default || c))
export const ModuleProductFeatured = () => import('../../components/ModuleProductFeatured.vue' /* webpackChunkName: "components/module-product-featured" */).then(c => wrapFunctional(c.default || c))
export const ModuleProductFeaturedComparison = () => import('../../components/ModuleProductFeaturedComparison.vue' /* webpackChunkName: "components/module-product-featured-comparison" */).then(c => wrapFunctional(c.default || c))
export const ModuleProductFeaturedPrices = () => import('../../components/ModuleProductFeaturedPrices.vue' /* webpackChunkName: "components/module-product-featured-prices" */).then(c => wrapFunctional(c.default || c))
export const ModuleProductInnerText = () => import('../../components/ModuleProductInnerText.vue' /* webpackChunkName: "components/module-product-inner-text" */).then(c => wrapFunctional(c.default || c))
export const ModuleProductLeaf = () => import('../../components/ModuleProductLeaf.vue' /* webpackChunkName: "components/module-product-leaf" */).then(c => wrapFunctional(c.default || c))
export const ModuleProductPriceOpinion = () => import('../../components/ModuleProductPriceOpinion.vue' /* webpackChunkName: "components/module-product-price-opinion" */).then(c => wrapFunctional(c.default || c))
export const ModuleProductRanking = () => import('../../components/ModuleProductRanking.vue' /* webpackChunkName: "components/module-product-ranking" */).then(c => wrapFunctional(c.default || c))
export const ModuleProductSimilar = () => import('../../components/ModuleProductSimilar.vue' /* webpackChunkName: "components/module-product-similar" */).then(c => wrapFunctional(c.default || c))
export const ModuleProducts = () => import('../../components/ModuleProducts.vue' /* webpackChunkName: "components/module-products" */).then(c => wrapFunctional(c.default || c))
export const ModuleRABanner = () => import('../../components/ModuleRABanner.vue' /* webpackChunkName: "components/module-r-a-banner" */).then(c => wrapFunctional(c.default || c))
export const ModuleRAButton = () => import('../../components/ModuleRAButton.vue' /* webpackChunkName: "components/module-r-a-button" */).then(c => wrapFunctional(c.default || c))
export const ModuleRACarrousel = () => import('../../components/ModuleRACarrousel.vue' /* webpackChunkName: "components/module-r-a-carrousel" */).then(c => wrapFunctional(c.default || c))
export const ModuleRAFaqs = () => import('../../components/ModuleRAFaqs.vue' /* webpackChunkName: "components/module-r-a-faqs" */).then(c => wrapFunctional(c.default || c))
export const ModuleRAGrid = () => import('../../components/ModuleRAGrid.vue' /* webpackChunkName: "components/module-r-a-grid" */).then(c => wrapFunctional(c.default || c))
export const ModuleRAHighlight = () => import('../../components/ModuleRAHighlight.vue' /* webpackChunkName: "components/module-r-a-highlight" */).then(c => wrapFunctional(c.default || c))
export const ModuleRAOpening = () => import('../../components/ModuleRAOpening.vue' /* webpackChunkName: "components/module-r-a-opening" */).then(c => wrapFunctional(c.default || c))
export const ModuleRAOrderedList = () => import('../../components/ModuleRAOrderedList.vue' /* webpackChunkName: "components/module-r-a-ordered-list" */).then(c => wrapFunctional(c.default || c))
export const ModuleRAProducts = () => import('../../components/ModuleRAProducts.vue' /* webpackChunkName: "components/module-r-a-products" */).then(c => wrapFunctional(c.default || c))
export const ModuleRATextRotate = () => import('../../components/ModuleRATextRotate.vue' /* webpackChunkName: "components/module-r-a-text-rotate" */).then(c => wrapFunctional(c.default || c))
export const ModuleRace = () => import('../../components/ModuleRace.vue' /* webpackChunkName: "components/module-race" */).then(c => wrapFunctional(c.default || c))
export const ModuleRecommendations = () => import('../../components/ModuleRecommendations.vue' /* webpackChunkName: "components/module-recommendations" */).then(c => wrapFunctional(c.default || c))
export const ModuleReview = () => import('../../components/ModuleReview.vue' /* webpackChunkName: "components/module-review" */).then(c => wrapFunctional(c.default || c))
export const ModuleSkin = () => import('../../components/ModuleSkin.vue' /* webpackChunkName: "components/module-skin" */).then(c => wrapFunctional(c.default || c))
export const ModuleSkinBanner = () => import('../../components/ModuleSkinBanner.vue' /* webpackChunkName: "components/module-skin-banner" */).then(c => wrapFunctional(c.default || c))
export const ModuleSlider = () => import('../../components/ModuleSlider.vue' /* webpackChunkName: "components/module-slider" */).then(c => wrapFunctional(c.default || c))
export const ModuleTexto = () => import('../../components/ModuleTexto.vue' /* webpackChunkName: "components/module-texto" */).then(c => wrapFunctional(c.default || c))
export const ModuleTops = () => import('../../components/ModuleTops.vue' /* webpackChunkName: "components/module-tops" */).then(c => wrapFunctional(c.default || c))
export const ModuleYoutube = () => import('../../components/ModuleYoutube.vue' /* webpackChunkName: "components/module-youtube" */).then(c => wrapFunctional(c.default || c))
export const NewChatButton = () => import('../../components/NewChatButton.vue' /* webpackChunkName: "components/new-chat-button" */).then(c => wrapFunctional(c.default || c))
export const NewCommentButton = () => import('../../components/NewCommentButton.vue' /* webpackChunkName: "components/new-comment-button" */).then(c => wrapFunctional(c.default || c))
export const NewCommentModule = () => import('../../components/NewCommentModule.vue' /* webpackChunkName: "components/new-comment-module" */).then(c => wrapFunctional(c.default || c))
export const NewInquiryButton = () => import('../../components/NewInquiryButton.vue' /* webpackChunkName: "components/new-inquiry-button" */).then(c => wrapFunctional(c.default || c))
export const NewsLettersCard = () => import('../../components/NewsLettersCard.vue' /* webpackChunkName: "components/news-letters-card" */).then(c => wrapFunctional(c.default || c))
export const OpinionCard = () => import('../../components/OpinionCard.vue' /* webpackChunkName: "components/opinion-card" */).then(c => wrapFunctional(c.default || c))
export const OutletLinkCard = () => import('../../components/OutletLinkCard.vue' /* webpackChunkName: "components/outlet-link-card" */).then(c => wrapFunctional(c.default || c))
export const PriceCard = () => import('../../components/PriceCard.vue' /* webpackChunkName: "components/price-card" */).then(c => wrapFunctional(c.default || c))
export const PriceCardPrice = () => import('../../components/PriceCardPrice.vue' /* webpackChunkName: "components/price-card-price" */).then(c => wrapFunctional(c.default || c))
export const PriceNotFound = () => import('../../components/PriceNotFound.vue' /* webpackChunkName: "components/price-not-found" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardInnerText = () => import('../../components/ProductCardInnerText.vue' /* webpackChunkName: "components/product-card-inner-text" */).then(c => wrapFunctional(c.default || c))
export const ProductCardModule = () => import('../../components/ProductCardModule.vue' /* webpackChunkName: "components/product-card-module" */).then(c => wrapFunctional(c.default || c))
export const ProductCardModuleOne = () => import('../../components/ProductCardModuleOne.vue' /* webpackChunkName: "components/product-card-module-one" */).then(c => wrapFunctional(c.default || c))
export const ProductCardPriceOpinion = () => import('../../components/ProductCardPriceOpinion.vue' /* webpackChunkName: "components/product-card-price-opinion" */).then(c => wrapFunctional(c.default || c))
export const ProductCardPrincipal = () => import('../../components/ProductCardPrincipal.vue' /* webpackChunkName: "components/product-card-principal" */).then(c => wrapFunctional(c.default || c))
export const ProductCardSecondary = () => import('../../components/ProductCardSecondary.vue' /* webpackChunkName: "components/product-card-secondary" */).then(c => wrapFunctional(c.default || c))
export const ProductCardStandard = () => import('../../components/ProductCardStandard.vue' /* webpackChunkName: "components/product-card-standard" */).then(c => wrapFunctional(c.default || c))
export const ProductFinder = () => import('../../components/ProductFinder.vue' /* webpackChunkName: "components/product-finder" */).then(c => wrapFunctional(c.default || c))
export const ProductHeader = () => import('../../components/ProductHeader.vue' /* webpackChunkName: "components/product-header" */).then(c => wrapFunctional(c.default || c))
export const ProductHeaderButtons = () => import('../../components/ProductHeaderButtons.vue' /* webpackChunkName: "components/product-header-buttons" */).then(c => wrapFunctional(c.default || c))
export const ProductReview = () => import('../../components/ProductReview.vue' /* webpackChunkName: "components/product-review" */).then(c => wrapFunctional(c.default || c))
export const RaceCard = () => import('../../components/RaceCard.vue' /* webpackChunkName: "components/race-card" */).then(c => wrapFunctional(c.default || c))
export const RaceCardModule = () => import('../../components/RaceCardModule.vue' /* webpackChunkName: "components/race-card-module" */).then(c => wrapFunctional(c.default || c))
export const RaceFinder = () => import('../../components/RaceFinder.vue' /* webpackChunkName: "components/race-finder" */).then(c => wrapFunctional(c.default || c))
export const ReviewCard = () => import('../../components/ReviewCard.vue' /* webpackChunkName: "components/review-card" */).then(c => wrapFunctional(c.default || c))
export const ReviewList = () => import('../../components/ReviewList.vue' /* webpackChunkName: "components/review-list" */).then(c => wrapFunctional(c.default || c))
export const SliderCardArticle = () => import('../../components/SliderCardArticle.vue' /* webpackChunkName: "components/slider-card-article" */).then(c => wrapFunctional(c.default || c))
export const SliderCardFinder = () => import('../../components/SliderCardFinder.vue' /* webpackChunkName: "components/slider-card-finder" */).then(c => wrapFunctional(c.default || c))
export const SliderCardProduct = () => import('../../components/SliderCardProduct.vue' /* webpackChunkName: "components/slider-card-product" */).then(c => wrapFunctional(c.default || c))
export const SliderCardPromocion = () => import('../../components/SliderCardPromocion.vue' /* webpackChunkName: "components/slider-card-promocion" */).then(c => wrapFunctional(c.default || c))
export const StoreLeaf = () => import('../../components/StoreLeaf.vue' /* webpackChunkName: "components/store-leaf" */).then(c => wrapFunctional(c.default || c))
export const TextSearchFilter = () => import('../../components/TextSearchFilter.vue' /* webpackChunkName: "components/text-search-filter" */).then(c => wrapFunctional(c.default || c))
export const UserVerifyForm = () => import('../../components/UserVerifyForm.vue' /* webpackChunkName: "components/user-verify-form" */).then(c => wrapFunctional(c.default || c))
export const VideoCardH = () => import('../../components/VideoCardH.vue' /* webpackChunkName: "components/video-card-h" */).then(c => wrapFunctional(c.default || c))
export const VideoCardV = () => import('../../components/VideoCardV.vue' /* webpackChunkName: "components/video-card-v" */).then(c => wrapFunctional(c.default || c))
export const JqueryStub = () => import('../../components/jquery-stub.js' /* webpackChunkName: "components/jquery-stub" */).then(c => wrapFunctional(c.default || c))
export const NewOpinionButton = () => import('../../components/newOpinionButton.vue' /* webpackChunkName: "components/new-opinion-button" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
